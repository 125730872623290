import React, { useState, useEffect } from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby"

import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Article from "../../components/article"
import useWindowSize from "../../utilt/useWindowSize"

//CSS
import { eachTitle } from "./funnyDoll1000.module.css"
import { desc } from "./funnyDoll1000.module.css"
import { centButsWrapper } from "./funnyDoll1000.module.css"
import { centButs } from "./funnyDoll1000.module.css"
import { centBut } from "./funnyDoll1000.module.css"
import { centButCur } from "./funnyDoll1000.module.css"
import { thumNails } from "./funnyDoll1000.module.css"
import { curThumnail } from "./funnyDoll1000.module.css"
import { thumNail } from "./funnyDoll1000.module.css"
import { thumNailUme } from "./funnyDoll1000.module.css"
import { modalArea } from "./funnyDoll1000.module.css"
import { isShowCSS } from "./funnyDoll1000.module.css"
import { modalBg } from "./funnyDoll1000.module.css"
import { modalWrapperWrapper } from "./funnyDoll1000.module.css"
import { modalWrapperScale } from "./funnyDoll1000.module.css"
import { modalWrapper } from "./funnyDoll1000.module.css"
import { decription } from "./funnyDoll1000.module.css"
import { close } from "./funnyDoll1000.module.css"

import getLanguage from "../../utilt/getLangueage"
import calcModalSize from "../../utilt/calcModalSize"
//import getModalEventHol from "../../utilt/getModalEventHol"
import getIsTouchscreen from "../../utilt/getIsTouchscreen"

//import seoImg from "../../images/seo/seo_fd1000.jpg"


/**
 * ページ
 */
const FunnyDoll = (props) => {
  const [lang, setLang] = useState(getLanguage());
  function setLanguage(enOrJa) {
    setLang(enOrJa);
  }
  const winSize = useWindowSize() || props.size || { width: 640, height: 948 }


  //画像データ取得
  const data = useStaticQuery(graphql`query MyQueryFd1000 {
  allFile(
    filter: {extension: {regex: "/(jpg)/"}, relativeDirectory: {eq: "fd1000"}}
  ) {
    edges {
      node {
        base
        childImageSharp {
          gatsbyImageData(quality: 50, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
        }
      }
    }
  }
  allFd1000Json {
    edges {
      node {
        title
        descriptionJp
        description
      }
    }
  }
}
`)
  //1 取得した画像データを配列化
  const imagesOld = data.allFile.edges.map(n => n.node);
  const images = imagesOld.slice();
  //2 ファイル名でソート
  function compare(a, b) {
    const baseA = a.base;
    const baseB = b.base;

    let comparison = 0;
    if (baseA > baseB) {
      comparison = 1;
    } else if (baseA < baseB) {
      comparison = -1;
    }
    return comparison;
  }
  images.sort(compare);
  for (let i = 0; i < images.length; i++) {
    images[i].index = i;
  }
  //3 配列を二分してサムネイルとモーダル表示用画像を分ける
  const ImagesLength = Math.floor(images.length / 2);
  const modalImages = images.slice(0, ImagesLength);
  const thumbsImages = images.slice(ImagesLength);
  //4 サムネイルにindexを付ける
  for (let i = 0; i < ImagesLength; i++) {
    modalImages[i].index = i;
    thumbsImages[i].index = i;
  }
  //4a サムネイルを行ごとにさらに配列分け
  const thumbsCents = Array(10);
  for (let i = 0; i < 10; i++) {
    thumbsCents[i] = [];
  }
  for (let i = 0, j = 0; i < 1000; i++) {
    if (i !== 0 && i % 100 === 0) j++
    thumbsCents[j].push(thumbsImages[i])
    //thumbsCents[j].push(modalImages[i])
  }



  //5 JSONデータを配列化
  const descJsons = data.allFd1000Json.edges.map(n => n.node);
  //6 JSONもファイル名でソート
  function compareJason(a, b) {
    const baseA = a.title;
    const baseB = b.title;

    let comparison = 0;
    if (baseA > baseB) {
      comparison = 1;
    } else if (baseA < baseB) {
      comparison = -1;
    }
    return comparison;
  }
  descJsons.sort(compareJason);
  for (let i = 0; i < descJsons.length; i++) {
    descJsons[i].index = i;
  }


  /**
   * モダール用
   */
  const [isShow, setIsShow] = useState(false);
  const [picNum, setPicNum] = useState(0);
  const [cent, setCent] = useState(0);
  const [isAllShow, setIsAllShow] = useState(false);

  function handleModal(event, index) {
    event.stopPropagation();
    if (typeof index == "number") {
      setPicNum(index);
    }
    setIsShow(!isShow);
  }

  function handleModalKey(event, index) {
    if (event.keyCode === 13) { //Enter key
      if (typeof index == "number") {
        setPicNum(index);
      }
      setIsShow(true);
    }
  }


  function clickModal(event) {
    event.stopPropagation();
    const modal = document.getElementById("modalContent");
    const clientRect = modal.getBoundingClientRect();

    if (event.clientX > clientRect.left + clientRect.width * 0.5) {
      let result = (picNum + 1) % ImagesLength;
      setPicNum(result);
      if (result % 100 === 0) setCent(Math.floor(result / 100));
    } else {
      let result = picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1;
      setPicNum(result);
      if (result % 100 === 99) setCent(Math.floor(result / 100));
    }
  }


  //for touchscreen
  useEffect(() => {
    function plusPicNum() {
      let result = (picNum + 1) % ImagesLength;
      setPicNum(result);
      if (result % 100 === 0) setCent(Math.floor(result / 100));
    }

    function minusPicNum() {
      let result = picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1;
      setPicNum(result);
      if (result % 100 === 99) setCent(Math.floor(result / 100));
    }

    let handleTouchStart, handleTouchMove, handleTouchEnd;
    if (getIsTouchscreen()) {
      let startX;
      let moveX;
      let dist = 150;
      let isStart = false;

      handleTouchStart = e => {
        //e.preventDefault();
        if (!isStart) {
          isStart = true;
          startX = e.touches[0].pageX;
        }
      }
      handleTouchMove = e => {
        //e.preventDefault();
        moveX = e.changedTouches[0].pageX;
      }
      handleTouchEnd = e => {
        if (isStart) {
          isStart = false;
          if (startX && moveX) {
            if (startX > moveX && startX > moveX + dist) { // 右から左にスワイプ
              //e.preventDefault()
              plusPicNum();
            } else if (startX < moveX && startX + dist < moveX) { // 左から右にスワイプ
              //e.preventDefault()
              minusPicNum();
            }
            startX = null;
            moveX = null;
          }
        }
      }

      document.addEventListener('touchstart', handleTouchStart);
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd);
    }
    return () => {
      if (getIsTouchscreen()) {
        document.removeEventListener('touchstart', handleTouchStart);
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
      }
    }
  }, [picNum, cent, ImagesLength])



  useEffect(() => {
    function handlePicNumKey(event) {
      if (event.key === "ArrowRight" || event.key === "Tab") {
        let result = (picNum + 1) % ImagesLength;
        setPicNum(result);
        if (result % 100 === 0) setCent(Math.floor(result / 100));
      } else if (event.key === "ArrowLeft") {
        let result = picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1;
        setPicNum(result);
        if (result % 100 === 99) setCent(Math.floor(result / 100));
      } else if (event.keyCode === 27) {
        setIsShow(false);
      }
    }

    //画像選択にfocusをシンクロさせる
    if (isShow) {
      let cur = document.getElementById(`pic${String(picNum)}`);
      if (typeof cur !== "undefined") cur = document.getElementById(`titleFd1000`);
      cur.focus();
    }

    window.addEventListener('keydown', handlePicNumKey);
    return () => {
      window.removeEventListener('keydown', handlePicNumKey);
    }
  }, [picNum, cent, isShow, ImagesLength])


  function setCentAndIsall(num) {
    if (num === 10) {
      setIsAllShow(true)
    } else {
      setCent(num);
      setIsAllShow(false);
    }
  }

  const modaleScale = 3.8;

  return <>
    <Seo
      title="Funny Doll 1000"
      description={lang !== "ja" ?
        "1000 pencil drawings of girls with big heads" :
        "1.5等身（くらい）の女の子を描いた鉛筆画1000枚"
      }
      lang={lang}
      image="https://www.drawinghell.com/ogp_images/seo_fd1000.jpg"
      pathname="/work/funnyDoll1000"
    />


    {/**
       * ページ＆サムネイル
       */}
    <Layout
      checked="worksParts"
      winSize={winSize}
    >
      <Article
        isLangBar="true"
        winSize={winSize}
        setLang={setLanguage}
        lang={props.lang}
      >
        <h1
          className={eachTitle}
          id="titleFd1000"//focusの逃げ場用
        >
          {lang !== "ja" ? "Funny Doll 1000" : "Funny Doll 1000"}
        </h1>
        <p className={desc}>
          {lang !== "ja" ?
            `pencil on paper, 354mm × 257mm × 1000sheets, 1997-1999` :
            `鉛筆、紙、354mm × 257mm × 1000枚、1997-1999年`}
        </p>

        {/**
          * ボタン
         */}
        <div className={centButsWrapper}>
          <div
            className={centButs}
          >
            <button
              className={cent === 0 && !isAllShow ? `${centBut} ${centButCur}` : centBut}
              onClick={() => setCentAndIsall(0)}
            >
              0001 - 0100
            </button>
            <button
              className={cent === 1 && !isAllShow ? `${centBut} ${centButCur}` : centBut}
              onClick={() => setCentAndIsall(1)}
            >
              0101 - 0200
            </button>
            <button
              className={cent === 2 && !isAllShow ? `${centBut} ${centButCur}` : centBut}
              onClick={() => setCentAndIsall(2)}
            >
              0201 - 0300
            </button>
            <button
              className={cent === 3 && !isAllShow ? `${centBut} ${centButCur}` : centBut}
              onClick={() => setCentAndIsall(3)}
            >
              0301 - 0400
            </button>
            <button
              className={cent === 4 && !isAllShow ? `${centBut} ${centButCur}` : centBut}
              onClick={() => setCentAndIsall(4)}
            >
              0401 - 0500
            </button>
            <button
              className={cent === 5 && !isAllShow ? `${centBut} ${centButCur}` : centBut}
              onClick={() => setCentAndIsall(5)}
            >
              0501 - 0600
            </button>
            <button
              className={cent === 6 && !isAllShow ? `${centBut} ${centButCur}` : centBut}
              onClick={() => setCentAndIsall(6)}
            >
              0601 - 0700
            </button>
            <button
              className={cent === 7 && !isAllShow ? `${centBut} ${centButCur}` : centBut}
              onClick={() => setCentAndIsall(7)}
            >
              0701 - 0800
            </button>
            <button
              className={cent === 8 && !isAllShow ? `${centBut} ${centButCur}` : centBut}
              onClick={() => setCentAndIsall(8)}
            >
              0801 - 0900
            </button>
            <button
              className={cent === 9 && !isAllShow ? `${centBut} ${centButCur}` : centBut}
              onClick={() => setCentAndIsall(9)}
            >
              0901 - 1000
            </button>
            <button
              className={isAllShow ? `${centBut} ${centButCur}` : centBut}
              onClick={() => setCentAndIsall(10)}
            >
              ALL
            </button>

          </div>
        </div>


        {/**
         * サムネイル
        */}
        <div
          className={thumNails}
        >
          {(isAllShow ? modalImages : thumbsCents[cent]).map((node) => (
            <div
              className={isShow && picNum === node.index ? curThumnail : thumNail}
              onClick={event => handleModal(event, node.index)}
              onKeyDown={event => handleModalKey(event, node.index)}
              role="button"
              tabIndex={0}
              key={node.base.split(".")[0]}
              id={"pic" + String(node.index)}
              title={descJsons[node.index].title}
            >
              <GatsbyImage
                image={node.childImageSharp.gatsbyImageData}
                alt={node.base.split(".")[0]} />
            </div>
          ))}
          <div className={`${thumNail} ${thumNailUme} `}> </div>
          <div className={`${thumNail} ${thumNailUme} `}> </div>

        </div>
      </Article>
    </Layout>


    {/**
      * モーダル      
    */}
    <div
      className={
        isShow ? `${modalArea} ${isShowCSS}` : modalArea
      }
    >
      <div
        className={modalBg}
        onClick={event => handleModal(event)}
        onKeyDown={() => { }}
        role="button"
        tabIndex={0}
      >

        <div
          className={
            isShow ? `${modalWrapperWrapper} ${modalWrapperScale}` : modalWrapperWrapper
          }
          style={{
            maxWidth:
              calcModalSize(winSize,
                picNum === 999 ? 1028 * 0.2 : modalImages[picNum].childImageSharp.gatsbyImageData.width * 0.2,
                modalImages[picNum].childImageSharp.gatsbyImageData.width / modalImages[picNum].childImageSharp.gatsbyImageData.height, modaleScale, 0.9) + "px"
          }}
        >

          <div
            className={modalWrapper}
            onClick={event => clickModal(event)}
            onKeyDown={() => { }}
            role="button"
            tabIndex={0}
            id="modalContent"
          >
            <div>
              <GatsbyImage
                image={modalImages[picNum].childImageSharp.gatsbyImageData}
                alt={modalImages[picNum].base.split(".")[0]} />
              <div
                className={decription}
              >
                {lang !== "ja" ? descJsons[picNum].description : descJsons[picNum].descriptionJp}
              </div>

            </div>
          </div>
        </div>
      </div>
      <span
        className={close}
        onClick={event => handleModal(event)}
        onKeyDown={() => { }}
        role="button"
        tabIndex={0}
      >
        ×
      </span>
    </div>

  </>;
}

export default FunnyDoll
